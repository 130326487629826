/* Test Border class */
.TB {
  border: solid 1px red;
}

/**Main content Starts**/
.main_content {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 40px;
  padding-bottom: 40px;
  overflow-y: auto;
  overflow-x: hidden;
}

/**Main Content ends**/

.vh-100 {
  height: 100vh;
}

.vw-100 {
  width: 100vw;
}

.c-point {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.h-100 {
  height: 100%;
}

.h-75 {
  height: 75%;
}

.h-50 {
  height: 50%;
}

.h-25 {
  height: 25%;
}

.hw-100 {
  height: 100%;
  width: 100%;
}

.flexer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexer-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flexer-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.p-abs {
  position: absolute;
  border: solid 1px red;
}

.p-rel {
  position: relative;
}

.text-dark {
  color: var(--primaryBlack);
}

.text-light {
  color: white;
}

.text-muted {
  color: grey;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mb-5 {
  margin-bottom: 50px;
}

.p-1 {
  padding: 10px;
}

.p-2 {
  padding: 20px;
}

.p-3 {
  padding: 30px;
}

.p-4 {
  padding: 40px;
}

.p-5 {
  padding: 50px;
}

.px-1 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-2 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-3 {
  padding-left: 30px;
  padding-right: 30px;
}

.px-4 {
  padding-left: 40px;
  padding-right: 40px;
}

.px-5 {
  padding-left: 50px;
  padding-right: 50px;
}

.py-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-3 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-4 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-5 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.flexer-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.p-0 {
  padding: 0px;
}

.m-0 {
  margin: 0px;
}

.pm-0 {
  padding: 0px;
  margin: 0px;
}

.text-bold {
  font-weight: 700;
}

.text-danger {
  color: red;
  font-weight: 700;
}

.text-warning {
  color: 'var(--primaryYellow)';
  font-weight: 700;
}

.css-26l3qy-menu {
  z-index: 25 !important;
}

/**React datepicker global styles**/

.react-datepicker__preset {
  display: flex;
  flex-direction: row-reverse;
}

.react-datepicker__preset > .react-datepicker__month-container {
  box-shadow: 8px 8px 40px var(--primaryBlack10);
}

.react-datepicker__day-names {
  background: #fff;
  border-top: 1px solid var(--accentGraySecond);
  margin: 0;
}

.react-datepicker__day-name {
  color: var(--primaryBlack30);
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  margin-top: 12px;
  margin-bottom: 12px;
}

.react-datepicker__month {
  margin: 0;
  border: 1px solid var(--accentGraySecond);
  border-top: none;
  padding: 11px 14px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.react-datepicker__preset .react-datepicker__month {
  border-bottom-left-radius: 0px;
}

.react-datepicker__day--keyboard-selected {
  color: initial;
  background-color: initial;
}

.react-datepicker__day {
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlue);
  padding: 10px 0px;
  border-radius: 8px;
  margin: 2.5px;
  text-align: center;
  min-width: 26px;
}

.react-datepicker__day--selected {
  color: #00152e;
  background: var(--accentGraySecond);
  pointer-events: none;
}

.react-datepicker__day:hover {
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
}

.react-datepicker__day--outside-month {
  opacity: 0.1;
  pointer-events: none;
}

.react-datepicker {
  font-family: Nunito Sans;
  border: none;
  box-shadow: 8px 8px 40px var(--primaryBlack10);
  border-radius: 12px;
}

.react-datepicker:has(.react-datepicker__time-container) .react-datepicker__month {
  border-bottom-right-radius: 0px;
}
.react-datepicker:has(.react-datepicker__time-container) .react-datepicker__header {
  border-radius: 0px;
}

.react-datepicker:has(.react-datepicker__time-container) .react-datepicker__header--custom {
  border-top-right-radius: 0px !important;
}

.react-datepicker__month-text {
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlue);
  padding: 10px 13px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 8px;
  margin: 2.5px;
}

.react-datepicker__month-text:hover {
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
}

.react-datepicker-wrapper {
  position: relative;
  z-index: 10;
  width: 100%;
}

.react-datepicker-popper {
  z-index: 9999 !important;
  transform-style: flat;
}

.react-datepicker__month--selected {
  color: #00152e;
  background-color: var(--accentGray) !important;
  pointer-events: none;
}

.react-datepicker__month-text--keyboard-selected {
  background-color: initial;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header--custom {
  padding: 0;
  border: 1px solid var(--accentGraySecond);
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.react-datepicker__preset .react-datepicker__header--custom {
  border-top-left-radius: 0px !important;
}

.react-datepicker__preset .react-datepicker__header--custom > div {
  border-top-left-radius: 0px;
}

.react-datepicker__month {
  display: flex;
  flex-direction: column;
}

.react-datepicker__month-wrapper {
  display: flex;
}

.react-datepicker__time-container {
  border: 0px;
}

.react-datepicker__header--time {
  background-color: var(--accentGrayFourth);
  padding-block: 12.2px;
  border: 1px solid var(--accentGraySecond);
}

.react-datepicker__time-list-item {
  color: var(--primaryBlue);
  font-weight: 700;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: var(--primaryBlue10);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  color: var(--primaryBlack);
  font-weight: 700;
  background-color: var(--accentGraySecond);
}

/**React Select global styles**/

.react-multiselect__clear-indicator {
  display: none !important;
}

/**Dashboard Cards Carousel styles**/

.dashboard-cards-carousel {
  margin-left: -20px;
  overflow: visible !important;
}

.dashboard-cards-carousel__item {
  padding-left: 20px;
}

.dashboard-cards-carousel__item[aria-hidden='true'] {
  opacity: 0;
}

.ant-tooltip-inner {
  border-radius: 4px;
}

/**rc-tooltip styles **/
.rc-tooltip-no-transparent {
  opacity: 1 !important;
}

/** ant checkbox styles **/
.ant-checkbox-checked .ant-checkbox-inner::after {
  top: 45%;
  left: 25%;
}

.ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0075ff;
  border-color: #0075ff;
}
