body {
  margin: 0;
  color: #00152e;
  font-family: Nunito Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc;
}

:root {
  --primaryBlack: #00152e;
  --primaryBlack10: rgba(0, 21, 46, 0.1);
  --primaryBlack15: rgba(0, 21, 46, 0.15);
  --primaryBlack20: rgba(0, 21, 46, 0.2);
  --primaryBlack25: rgba(0, 21, 46, 0.25);
  --primaryBlack30: rgba(0, 21, 46, 0.3);
  --primaryBlack35: rgba(0, 21, 46, 0.35);
  --primaryBlack40: rgba(0, 21, 46, 0.4);
  --primaryBlack50: rgba(0, 21, 46, 0.5);
  --primaryBlack70: rgba(0, 21, 46, 0.7);
  --primaryBlack75: rgba(0, 21, 46, 0.75);
  --primaryBlack80: rgba(0, 21, 46, 0.8);
  --primaryBlack95: rgba(0, 21, 46, 0.95);
  --primaryBlack8: rgba(0, 21, 46, 0.08);
  --primaryBlack6: rgba(0, 21, 46, 0.06);
  --primaryBlack5: rgba(0, 21, 46, 0.05);
  --primaryBlack4: rgba(0, 21, 46, 0.04);
  --primaryBlack3: rgba(0, 21, 46, 0.03);
  --primaryBlack2: rgba(0, 21, 46, 0.02);
  --primaryBlack1: rgba(0, 21, 46, 0.01);
  --opaquePrimaryBlack35: rgba(127.5, 138, 135);
  --opaquePrimaryBlack50: rgba(127.5, 138, 150.5);
  --opaquePrimaryBlack60: rgba(127.5, 138, 160);
  --primaryGray: #fcfcfc;
  --secondaryGray: #fafafb;
  --primaryRed: #ff5959;
  --secondaryRed: #e70000;
  --tertiaryRed: #fe5151;
  --tertiaryRed5: rgba(254, 81, 81, 0.05);
  --tertiaryRed70: rgba(254, 81, 81, 0.7);
  --primaryPink: rgba(255, 156, 156, 1);
  --primaryRed5: rgba(255, 89, 89, 0.05);
  --primaryRed7: rgba(255, 89, 89, 0.07);
  --primaryRed10: rgba(255, 89, 89, 0.1);
  --primaryRed30: rgba(255, 89, 89, 0.3);
  --primaryRed50: rgba(255, 89, 89, 0.5);
  --primaryRed80: rgba(255, 89, 89, 0.8);

  --newRed: rgba(254, 81, 81, 1);
  --newRed3: rgba(254, 81, 81, 0.03);
  --newRed5: rgba(254, 81, 81, 0.05);
  --newRed10: rgba(254, 81, 81, 0.1);
  --newRed15: rgba(254, 81, 81, 0.15);
  --newRed20: rgba(254, 81, 81, 0.2);
  --newRed30: rgba(254, 81, 81, 0.3);
  --newRed35: rgba(254, 81, 81, 0.35);
  --newRed50: rgba(254, 81, 81, 0.5);
  --newRed65: rgba(254, 81, 81, 0.65);
  --newRed90: rgba(254, 81, 81, 0.9);

  --opaquePrimaryRed10: #ffeeee;
  --primaryRed20: rgba(255, 89, 89, 0.2);
  --primaryRed30: rgba(255, 89, 89, 0.3);
  --primaryRed40: rgba(255, 89, 89, 0.4);
  --primaryOrange: #ff8b37;
  --primaryYellow: #ffab2d;
  --primaryYellow10: rgba(255, 171, 45, 0.1);
  --primaryYellow20: rgba(255, 171, 45, 0.2);
  --primaryYellow15: rgba(255, 171, 45, 0.15);
  --primaryYellow30: rgba(255, 171, 45, 0.3);
  --primaryYellow50: rgba(255, 171, 45, 0.5);
  --primaryYellow65: rgba(255, 171, 45, 0.65);
  --primaryYellow90: rgba(255, 171, 45, 0.9);
  --secondaryYellow: #e68a00;
  --secondaryYellow60: rgba(230, 138, 0, 0.6);
  --secondaryYellow80: rgba(230, 138, 0, 0.8);
  --tertiaryYellow: #e58a00;
  --primaryGreen: #1eb76e;
  --primaryGreen5: rgba(30, 183, 110, 0.05);
  --primaryGreen10: rgba(30, 183, 110, 0.1);
  --primaryGreen20: rgba(30, 183, 110, 0.2);
  --primaryGreen30: rgba(30, 183, 110, 0.3);
  --primaryGreen50: rgba(30, 183, 110, 0.5);
  --primaryGreen65: rgba(30, 183, 110, 0.65);
  --primaryGreen70: rgba(30, 183, 110, 0.7);
  --primaryGreen90: rgba(30, 183, 110, 0.9);
  --shadowGreen: rgba(82, 181, 120, 0.2);
  --secondaryGreen: #078953;
  --secondaryGreen20: rgba(30, 183, 110, 0.2);
  --secondaryGreen80: rgba(30, 183, 110, 0.8);
  --tertiaryGreen: #09d8a6;
  --lightGreen: #20b056;
  --darkGreen: #14ae65;
  --darkGreen10: rgba(20, 174, 101, 0.1);
  --darkGreen6: rgba(20, 174, 101, 0.06);
  --primaryBlue: #0075ff; /* TODO: replace all used with --primaryBlue100 in the codebase */
  --primaryBlue5: rgba(0, 117, 255, 0.05);
  --primaryBlue3: rgba(0, 117, 255, 0.03);
  --primaryBlue2: rgba(0, 117, 255, 0.02);
  --primaryBlue50: rgba(0, 117, 255, 0.5);
  --primaryBlue20: rgba(0, 117, 255, 0.2);
  --primaryBlue10: rgba(0, 117, 255, 0.1);
  --primaryBlue15: rgba(0, 117, 255, 0.15);
  --primaryBlue65: rgba(0, 117, 255, 0.65);
  --primaryBlue90: rgba(0, 117, 255, 0.9);
  --secondaryBlue: #005ae1;
  --neutralBlue: #f5faff; /* TODO: replace all used with --primaryBlue100 in the codebase */
  --blueNormal10: #0075ff1a;
  --blueNormal100: #0075ff;
  --primaryPurple: #957aff;
  --primaryPurple90: rgba(149, 122, 255, 0.9);
  --primaryPurple65: rgba(149, 122, 255, 0.65);
  --primaryPurple60: rgba(149, 122, 255, 0.6);
  --primaryPurple50: rgba(149, 122, 255, 0.5);
  --primaryPurple30: rgba(149, 122, 255, 0.3);
  --primaryPurple20: rgba(149, 122, 255, 0.2);
  --primaryPurple10: rgba(149, 122, 255, 0.1);
  --secondaryPurple: #da6bf5;
  --additionalPurple: #6d48ff;
  --accentRed: rgba(255, 89, 89, 0.1);
  --accentGreen: rgba(30, 183, 110, 0.1);
  --dark7: rgba(64, 80, 98, 0.98);
  --primaryDarkGray: #8a8a8a;
  --accentDarkGray: #797979;
  --accentDarkGraySecond: #949494;
  --accentGray: #f5f5f5;
  --accentWhiterGray: #f7f7f7;
  --neutralGray: #e9e9e9;
  --lightGray: #e5e5e5;
  --accentGraySecond: #f3f3f3;
  --accentGrayThird: #d0d0d0;
  --accentGrayFourth: #f9f9f9;
  --accentGrayFifth: #f8f8f8;
  --accentGrayer: #f0f0f0;
  --backgroundGray: #88919d;
  --accentBlue: #ebf5fc;
  --accentYellow: #fff7ea;
  --accentPurple: #f4f1ff;
  --naturalAdditional3: #f3f3f3;
  --naturalAdditional7: #405062fa;
  --primaryDark: #00152e; /* TODO: replace all used with --dark100 in the codebase */
  --dark2: #00152e05;
  --dark5: #00152e0d;
  --dark10: #00152e1a;
  --dark15: #00152e26;
  --dark20: #00152e33;
  --dark30: #00152e4d;
  --dark50: #00152e80;
  --dark75: #00152ebf;
  --dark100: #00152e;
  --light100: #ffffff;
  --white8: rgba(255, 255, 255, 0.08);
  --white15: rgba(255, 255, 255, 0.15);
  --white20: rgba(255, 255, 255, 0.2);
  --white60: rgba(255, 255, 255, 0.6);
  --white70: rgba(255, 255, 255, 0.7);
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:before,
:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.clear {
  clear: both;
}

.clearfix:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  text-decoration: none;
}

input,
textarea {
  outline: none;
}

:active,
:focus {
  outline: 0;
  outline-style: none;
  outline-width: 0;
}

a:active,
a:focus {
  outline: 0;
  outline-style: none;
  outline-width: 0;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: 'Nunito Sans';
}

html,
body {
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: 400;
  -webkit-animation-duration: 0.1s;
  -webkit-animation-name: fontfix;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0.1s;
}

@-webkit-keyframes fontfix {
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-device-width: 767px) and (orientation: landscape) {
  body {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
}

@media screen and (max-device-width: 767px) and (orientation: portrait) {
  body {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
}

body {
  /*background: #E5E5E5;*/
  font-weight: 400;
}

#root {
  height: 100%;
  width: 100%;
}

#main {
  position: relative;
  display: flex;
  height: 100vh;
}

svg.icon-white path:not([fill='none']),
svg.icon-white rect:not([fill='none']),
svg.icon-white circle:not([fill='none']) {
  fill: white !important;
}
