.tooltip-secondary .rc-tooltip-inner {
  padding: 4px 6px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background: var(--dark7);
  border-radius: 6px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
  min-height: 34px;
}

.tooltip-secondary.rc-tooltip-placement-top .rc-tooltip-arrow,
.tooltip-secondary.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.tooltip-secondary.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-right-color: var(--dark7);
}

.tooltip-secondary.rc-tooltip-placement-right .rc-tooltip-arrow,
.tooltip-secondary.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.tooltip-secondary.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: var(--dark7);
}

.tooltip-secondary.rc-tooltip-placement-left .rc-tooltip-arrow,
.tooltip-secondary.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.tooltip-secondary.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: var(--dark7);
}

.tooltip-secondary.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.tooltip-secondary.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.tooltip-secondary.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: var(--dark7);
}
